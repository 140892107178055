<template>
  <div>
    <b-card>
      <div class="row mb-3">
        <div class="col-12 d-flex">
          <span class="h3 mr-auto text-secondary"> Nouvelle équipe</span>

          <div>
            <modal-actions @close="$router.go(-1)" />
          </div>
        </div>
      </div>

      <div class="shdow">
        <div
          class="d-flex flex-wrap justify-content-between align-items-center"
        >
          <span class="h4 text-secondary">Informations générales</span>
          <div>
            <b-button
              @click="addItem"
              type="submit"
              pill
              :disabled="submitingForm"
            >
              <b-spinner
                small
                v-if="submitingForm"
                class="text-white"
                label="Loading..."
              ></b-spinner>
              Confirmer</b-button
            >
          </div>
        </div>
        <hr class="mt-1 mb-3 bg-secondary" />

        <div>
          <br />
          <div class="row">
            <div class="col-12 col-md-6">
              <b-form-group
                label-cols="4"
                label="Libellé équipe :"
                label-class="font-weight-bold"
              >
                <b-form-input
                  placeholder="Libellé équipe"
                  v-model="newTeam.name"
                  type="text"
                  :class="{
                    'is-invalid':
                      $v.newTeam.name.$error && $v.newTeam.name.$dirty,
                  }"
                ></b-form-input>
                <!-- ERROR MESSAGES -->
                <span
                  style="font-size: x-small"
                  class="text-danger font-weight-normal"
                  v-if="$v.newTeam.name.$dirty"
                >
                  {{
                    !$v.newTeam.name.required
                      ? "Champ obligatoire"
                      : !$v.newTeam.name.minLength
                      ? `Le champ doit contenir au moins ${$v.newTeam.name.$params.minLength.min} caractères.`
                      : ""
                  }}
                </span>
              </b-form-group>

              <b-form-group
                label-cols="4"
                label="Responsable :"
                label-class="font-weight-bold"
              >
                <v-select
                  label="text"
                  :reduce="(user) => user.value"
                  :options="responsables"
                  type="text"
                  placeholder="Responsable"
                  v-model="newTeam.responsable"
                  class="font-weight-normal"
                  style="padding-right: 0 !important"
                >
                  <template #no-options> Liste vide </template>
                </v-select>
              </b-form-group>

              <b-form-group
                label-cols="4"
                label="Entité :"
                label-class="font-weight-bold"
              >
                <v-select
                  label="text"
                  :reduce="({ value }) => value"
                  :options="entitesList"
                  placeholder="Entité"
                  v-model="selectedEntite"
                  style="padding-right: 0 !important"
                  :class="{
                    'is-invalid form-control p-0':
                      $v.selectedEntite.$error && $v.selectedEntite.$dirty,
                  }"
                  @input="newTeam.organisation = null"
                ></v-select>
                <!-- ERROR MESSAGES -->
                <span
                  style="font-size: x-small"
                  class="text-danger font-weight-normal"
                  v-if="$v.selectedEntite.$dirty"
                >
                  {{ !$v.selectedEntite.required ? "Champ obligatoire" : "" }}
                </span>
              </b-form-group>

              <b-form-group
                label-cols="4"
                label="Organisation de rattachement :"
                label-class="font-weight-bold"
              >
                <v-select
                  label="text"
                  :reduce="(user) => user.value"
                  :options="organisations"
                  type="text"
                  placeholder="Organisation"
                  v-model="newTeam.organisation"
                  style="padding-right: 0 !important"
                  :class="{
                    'is-invalid form-control p-0':
                      $v.newTeam.organisation.$error &&
                      $v.newTeam.organisation.$dirty,
                  }"
                  @input="
                    () => {
                      newTeam.type = newTeam.organisation
                        ? ORGANISATIONS.find(
                            (orga) => orga['@id'] === newTeam.organisation
                          ).type
                        : null;
                    }
                  "
                >
                  <template #no-options> Liste vide </template>
                </v-select>

                <span
                  style="font-size: x-small"
                  class="text-danger font-weight-normal"
                  v-if="$v.newTeam.organisation.$dirty"
                >
                  {{
                    !$v.newTeam.organisation.required ? "Champ obligatoire" : ""
                  }}
                </span>
              </b-form-group>

              <b-form-group
                label-cols="4"
                label="Equipe de rattachement :"
                label-class="font-weight-bold"
              >
                <v-select
                  label="text"
                  :reduce="({ value }) => value"
                  :options="teamsList"
                  type="text"
                  placeholder="Equipe de rattachement"
                  v-model="newTeam.parent"
                  style="padding-right: 0 !important"
                  @input="newTeam.niveau = newTeam.parent ? 2 : 1"
                >
                  <template #no-options> Liste vide </template>
                </v-select>
              </b-form-group>
            </div>

            <div class="col-12 col-md-6">
              <b-form-group
                label-cols="4"
                label="Type organisation :"
                label-class="font-weight-bold"
              >
                <v-select
                  :options="ORG_TYPES"
                  placeholder="Type organisation"
                  v-model="newTeam.type"
                  disabled
                  style="padding-right: 0 !important"
                  :class="{
                    'is-invalid form-control p-0':
                      $v.newTeam.type.$error && $v.newTeam.type.$dirty,
                  }"
                ></v-select>
                <!-- ERROR MESSAGES -->
                <span
                  style="font-size: x-small"
                  class="text-danger font-weight-normal"
                  v-if="$v.newTeam.type.$dirty"
                >
                  {{ !$v.newTeam.type.required ? "Champ obligatoire" : "" }}
                </span>
              </b-form-group>

              <b-form-group
                label-cols="4"
                label="Niveau :"
                label-class="font-weight-bold"
              >
                <b-form-input
                  :options="ORG_TYPES"
                  placeholder="Niveau"
                  type="number"
                  v-model="newTeam.niveau"
                  disabled
                  style="padding-right: 0 !important"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                label-cols="4"
                label="Description :"
                label-class="font-weight-bold"
              >
                <b-form-textarea
                  v-model="newTeam.description"
                  placeholder="Description"
                  class="form-control W-50"
                  id="exampleFormControlTextarea1"
                  rows="6"
                ></b-form-textarea>
              </b-form-group>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div class="d-flex mb-2">
        <span class="h4 text-secondary mr-auto my-auto"
          >Liste des collaborateurs
        </span>

        <div class="d-flex justify-content-end">
          <b-button
            style="
              background-color: #007bffff !important;
              border-color: #007bffff !important;
            "
            variant="secondary"
            disabled
            class="mb-2 align-btn-table-col"
            ><i class="mdi mdi-plus"></i> Ajouter un collaborateur
          </b-button>
        </div>
      </div>
      <div class="col-12 p-0">
        <hr class="mt-1 mb-3 bg-secondary" />
      </div>

      <div class="row">
        <div class="col-12">
          <b-table
            :items="[]"
            :fields="collaborators.tableFields"
            responsive
            show-empty
            bordered
            striped
          >
            <template #empty>
              <div class="text-center text-gray">Table vide</div>
            </template>
            <template v-slot:cell(actions)="data">
              <table-actions
                class="justify-content-center"
                :actions="data.value"
              />
            </template>
          </b-table>
        </div>
      </div>
    </b-card>
    <div v-if="addCollaborators" to="modals-xyz-548">
      <Modal @close="addCollaborators = !addCollaborators">
        <add-collaborators />
      </Modal>
    </div>
  </div>
</template>

<script>
import ModalActions from "@/components/custom/ModalActions.vue";
import TableActions from "@/components/custom/TableActions.vue";
import Modal from "@/components/custom/Modal.vue";
import AddCollaborators from "../components/AddCollaborators.vue";
import { mapGetters } from "vuex";
import { minLength, required } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import { USER_URL } from "@/helpers/constURL";

export default {
  components: {
    ModalActions,
    TableActions,
    Modal,
    AddCollaborators,
  },
  data: () => ({
    editMode: false,
    addCollaborators: false,
    submitingForm: false,
    newTeam: {
      name: "",
      responsable: [],
      description: "",
      organisation: "",
      parent: null,
      niveau: 1,
    },
    selectedEntite: null,
    collaborators: {
      tableFields: [
        { key: "full_name", label: "Nom complet", sortable: true },
        { key: "mail", label: "Mail", sortable: true },
        {
          key: "organisation_attachment",
          label: "Organisation de rattachement",
          sortable: true,
        },
        { key: "function", label: "Fonction", sortable: true },
        { key: "account", label: "Compte", sortable: true },
        {
          key: "actions",
          label: "Actions",
          thStyle: { width: "150px !important", minWidth: "150px !important" },
          sortable: false,
        },
      ],
    },
  }),
  validations: {
    newTeam: {
      name: {
        required,
        minLength: minLength(5),
      },
      organisation: {
        required,
      },
      // responsable: {
      //   required,
      // },
      type: {
        required,
      },
    },
    selectedEntite: {
      required,
    },
  },

  methods: {
    addItem() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        var item = {
          name: this.newTeam.name,
          manager: this.newTeam.responsable,
          organisation: this.newTeam.organisation,
          parent: this.newTeam.parent,
          description: this.newTeam.description,
          type: this.newTeam.type,
          niveau: this.newTeam.niveau,
        };
        this.$store.dispatch("teams/createTeam", item).then(() => {
          Swal.fire({
            title: "L'équipe est bien créée !",
            type: "success",
            confirmButtonText: "OK",
          }).then((result) => {
            if (result.value) {
              this.$router.push({
                name: "show-team",
                params: {
                  slug: this.TEAM.slug || "abcd",
                  id: this.TEAM.id,
                  fromCreation: true,
                },
              }); //redirect to the new task
            }
          });
        });
      }
    },
  },
  created() {
    this.$store.dispatch("users/fetchAllResponsables");
    this.$store.dispatch("organisation/fetchAllOrganisations");
    this.$store.dispatch("entity/fetchAllEntities");
    this.$store.dispatch("teams/fetchAllTeams");
    this.$store.dispatch("type/fetchOrganisationTypes");
  },
  computed: {
    ...mapGetters("users", ["RESPONSABLES"]),
    ...mapGetters("organisation", ["ORGANISATIONS"]),
    ...mapGetters("teams", ["TEAM", "TEAMS"]),
    ...mapGetters("entity", ["ENTITIES"]),
    ...mapGetters("type", ["ORG_TYPES"]),

    responsables() {
      return this.RESPONSABLES.filter(
        (responsable) =>
          !this.TEAMS.find(({ manager }) => manager.id === responsable.id)
      ).map((responsable) => ({
        value: `/api/${USER_URL}/${responsable.id}`,
        text: responsable.firstname + " " + responsable.lastname,
      }));
    },
    entitesList() {
      return this.ENTITIES.map((entity) => ({
        value: entity.id,
        text: entity.libelle,
      }));
    },
    organisations() {
      return this.ORGANISATIONS.filter((organisation) =>
        this.selectedEntite && organisation.entite
          ? parseInt(organisation.entite.id) === parseInt(this.selectedEntite)
          : false
      ).map((org) => ({
        value: org["@id"],
        text: org.libelle,
      }));
    },
    teamsList() {
      return this.newTeam.organisation
        ? this.TEAMS.filter(
            (team) =>
              team.organisation["@id"] === this.newTeam.organisation &&
              team.niveau === 1
          ).map((team) => ({
            value: team["@id"],
            text: team.name,
          }))
        : [];
    },
  },
};
</script>

<style></style>
